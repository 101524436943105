/* .pcg-page-wrapper body {
  line-height: 0;
}
.pcg-page-wrapper main.pcg-main-content {
  line-height: 1.8;
} */
h1, h2, h3, h4, p, li, label, legend, tr, input, textarea, dt, dd, span, h4, h4.basket, dl.summary, .pcgf-default * {
	font-family: inherit !important;
	line-height: inherit;
	font-size: inherit;
}

sc-fill-form-app {
  font-size: 1rem;
}

#myLifeExcerpt img,
#myAccountExcerpt img,
.contact-type.Yes.Email .ss-option-2,
.contact-type.Yes.Mobile .ss-option-1,
.contact-type.paper .ss-option-2,
.ppl-employer-role .form-btn.js-change-my-address-button,
.show-workas,
.case-managers-roles .show-cm {
  display: none;
}

/* Only display these form fields when in work as mode */
.pcg-work-as .show-workas {
  display: block;
}

.prettify-astericks .forms-mandatory {
  display: none !important;
}


#maincontent > p { margin-bottom: 0;}

.pcgf-default .form-address-search__custom-address-form button:not(.tox-tbtn), 
.pcgf-default .form-main__questions-container button:not(.tox-tbtn) {
  background-color: #237F86 !important;
}

.pcgf-default .form-main__questions-container table th {
  background: none;
  background-color:  #237F86 !important;
}

:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

.span_3_of_12 {
    width: 23.8% !important;
}

.right-column,
.right-columnAlt {
    min-width: inherit;
}

.right-columnAlt {

  width: auto;
  float: none;
  margin-left: 0;
  padding-right: 0;
  margin: 0 20px;
}

.left-columnAlt {
  width: 100%;
  margin-left: 0;
  float: none;
  clear: both;
}

.right-column #app,
.right-columnAlt #app {
    margin-right: 20px;
}

.center-content {
  padding: 0 0 1rem 0;
  margin: 0;
}

#liquid-round, .liquid-round {
  margin: 0;
}

.liquid-round .col {
  display: inline-block;
  width: inherit;
}

.one-column-layout {
  padding: 0;
}

.floating-back-to-top-button.unStuck,
.floating-back-to-top-button,
.left-columnAlt {
  position: fixed;
  float: right;
  margin-top: 20px;
  margin-right: 0px;
  width: 80px;
  z-index: 100;
  right: 1rem;

  display: none !important;
}

.pcg-navigation__item {
  color: white;
}

.messages-notigication {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  background-color: white;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.2;
  color: #484848;
  font-weight: bold;
  margin-left: 0.2rem;
}

input[type=text], 
input[type=password], 
input[type=number], 
input[type=date], 
textarea, 
select {
    border: 1px solid #bbb;
    border-radius: 0;
    padding: 5px;
    box-shadow: none !important;
}

input[type=text]:focus, 
input[type=password]:focus, 
textarea:focus {
    outline: -webkit-focus-ring-color auto 5px;
}

dl.summary {
  font-size: 1rem;
}

dl.summary dd {
  margin-bottom: 0;
}

dl.summary dt {
  padding-top: 13px;
  width: 200px;
  line-height: 1.2;
}

.pcg-page-wrapper .js-api-component .form-control {
  height: inherit;
}

#google_translate_element {
	position: relative;
	right: inherit;
	top: inherit;
	bottom: inherit;
	margin-right: inherit;
	float: none;
	height: inherit;
}

.tint4 {
  background: none;
}


.pcg-navigation__dropdown-list {
  overflow-y: scroll;
}


.dropdown-menu li a:hover {

  background-color: transparent;
  text-decoration: underline;
  color: #003399;
}

.pcgf-default h2.form-header__description,
.pcg-page-wrapper h2.form-header__description {
  font-size: 1rem;
  font-weight: 400;
}

.pcgf-default .forms-mandatory {
  color: black;
}

.question-type-singlecheckbox input {
  float: left;
  margin-top: 0.5rem;
}

.question-type-singlecheckbox label {
  padding-left: 2rem;
}

.question-type-singlecheckbox p,
.question-type-singlecheckbox .forms-mandatory {
  display: inline;
}

.question-type-singlecheckbox .forms-mandatory {
  color: red;
}

.form-radio__option,
.form-checkbox__option {
  position: relative;
}
.form-radio__option input,
.form-checkbox__option input {
  position: absolute;
  top: 0.5rem;
  left: 0;
}

.form-radio__option label,
.form-checkbox__option label {
  padding-left: 1.5rem;
}


.react-autosuggest__suggestions-list {
  display: none !important;
}

.pcg-page-wrapper .pcg-content-jumbotron {
  background-color: #237f86;
}

/* From old site */


.ss-panel {
  display: none;
  margin-top: 10px !important;
  width: 400px;
  clear: none !important;
  position: absolute !important;
  left: 450px;
  top: -150px;
  z-index: 100;
  background: rgb(237, 243, 244);
  padding: 20px;
  border: 6px solid #127c85;
  border-radius: 10px;
}


  .ss-panel.fixed {
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
  }


.arrow-left {
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-right: 30px solid #127c85;
  position: absolute !important;
  left: -30px;
  top: 150px;
}


      .icon-driven .ss-panel {
        top: 0;
        left: inherit;
        right: 0px;
        width: 400px;
      }

      .icon-driven .arrow-left {
        top: 0px;
      }

      .icon-driven .social-security-template {
        background-size: 100% 100%;
        width: 90%;
        height: 220px;
    }

.question-type-text.social-security {
  clear: left;
}


.social-security-template {
  font-family: monospace !important;
  background-image: url(/images/social-security-background.png);
  background-repeat: no-repeat;
  padding: 20px;
  border-radius: 5px;
  /* width: 460px;
  height: 261px; */
  text-align: center;
  position: relative;
  display: block;


  background-size: 100% 100%;
  width: 100%;
  height: 240px;
}

.ss-panel .info-section {
  padding: 10px 10px 0 10px
}

.ss-panel .info-section p {
  float: none;
}


.js-whatsThisBtn {
  margin-bottom: 0px;
  line-height: 1.4;
  vertical-align: top;
}


.form-main__tip {
  position: fixed;
  bottom: 0;
  background-color: #ffffb3;
  left: 0;
  width: 100vw;
  max-height: 50px;
  padding: 20px 0;
  z-index: 100;
  text-align: center;
}

.form-main__tip strong {
  padding: 0 20px;
  display: block;
}

.hasDatepicker {
  max-width: 160px;
}

.form-main__questions-container .ui-datepicker-trigger {
  top: 52px;
}



.form-validation-summary{
  padding: 20px;
  background-color: #fff4a3;
  font-weight: 700;
}


.forms-feedback {
  /* padding: 10px; */
  background-color: #fff4a3;
  font-weight: 700;
  display: inline-block;
}

.question-type-singlecheckbox {
  overflow: hidden;
}

.form-default .form-date__control input {
	float: none;
}

.form-date__control img {
  top: 10px;
}



/* Icon driven */
.icon-driven .form-radio__option,
.icon-driven .form-checkbox__option {
  width: 100%;
  overflow: inherit;
  position: relative;
}

.icon-driven .form-radio__option img,
.icon-driven .form-checkbox__option img {
  vertical-align: middle;
  width: 100px;
  float: left;
  margin-right: 20px;
}

.icon-driven .form-radio__option label,
.icon-driven .form-checkbox__option label {
  display: block;
  padding: 20px;
  border: 5px #e5e1e0 solid;
  border-radius: 5px;
  width: 90%;
  transition: all 0.4s ease;
  line-height: 1.2 !important;
  margin-bottom: 10px;
}

.icon-driven .form-radio__option label:before,
.icon-driven .form-radio__option label:after,
.icon-driven .form-checkbox__option label:before,
.icon-driven .form-checkbox__option label:after {
  display: none;
}


.icon-driven .form-radio__option input:checked + label,
.icon-driven .form-checkbox__option input:checked + label {
  background-color: #97c2c5;
  border-color: #0f7b84;

}

.icon-driven input {
  position: absolute;
  left: -9999px;
}

.showMore-container {
  display: block;
  clear: both;
  padding-top: 1rem;
  font-size: 1.5rem;
  padding-bottom: 1rem;
}

.icon-driven .other-docType {
  display:none;
}

.icon-driven .show-more-btn {
  font-size: 1.2rem;
}

.icon-driven .show-more-btn:hover {
  cursor: pointer;
}

.icon-driven .show-more-btn span {
  color: white;
  padding-left: 0.5rem;
  font-size: 1.2rem;
}


/* Icon driven ends */



/* Icon driven inline */
.inline-icon-driven .form-radio__option {
  overflow: inherit;
  display: inline;
}

.inline-icon-driven .form-radio__option img {
  vertical-align: middle;
  width: 100px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.inline-icon-driven .form-radio__option label {
  display: block;
  padding: 20px;
  border: 5px #e5e1e0 solid;
  border-radius: 5px;
  transition: all 0.4s ease;
  line-height: 1.2 !important;
  margin-bottom: 10px;
  text-align: center;
}

.inline-icon-driven .form-radio__option label:before,
.inline-icon-driven .form-radio__option label:after {
  display: none;
}


.inline-icon-driven .form-radio__option input:checked + label {
  background-color: #97c2c5;
  border-color: #0f7b84;

}

.inline-icon-driven input {
  position: absolute;
  left: -9999px;
}

.inline-icon-driven .form-radio__option label:hover {
  cursor: pointer;
}
/* Icon driven inline ends*/
.enrollment-list-view {
  max-width: 500px;
}

.enrollment-list-view .section {
  background-color: #f8f8f8;
  border-bottom: 1px solid #d0d0d0;
  padding: 10px 20px;
}

.enrollment-list-view label {
  float: none;
  margin: 0;
  font-weight: bold;
}

.enrollment-list-view input {
  width: inherit;
  margin-right: 20px;
}

#consumerId {
  min-height: 40px;
  float: left;
}

.consumer-btn,
.orangeButtons a {

  background: #127c85 !important;
  border: 2px solid #127c85 !important;
  color: white !important;
  width: 150px;
  height: 40px;
  border-radius: 40px !important;
  line-height: 3px !important;
  padding: 20px !important;
  text-shadow: initial !important;
  float: left;
  margin-left: 10px !important;
}

.orangeButtons a img {
  display: none;
}


/* Old site ends */




.pcg-header {
    top: 0;
}

.sc-program-announcement {
  background-color: pink;
  text-align: center; 
  margin-bottom: 0;
  border-radius: 0;
  color: black;
  font-size: 1rem;
}

.sc-program-announcement .container {
  padding: 1rem;
}

.pcg-header .alert {
  background-color: pink;
  text-align: center; 
  margin-bottom: 0;
  border-radius: 0;
  color: black;
  font-size: 1rem;
}

.pcg-header .alert:before {
  background-size: 30px 20px;
  background-image: url(/images/Icons/cogs.png);
  width: 30px;
  height: 20px;
  display: inline-block;
  margin-right: 0.5rem;
  content: "";
}

.pcg-header .alert button {
  background: transparent;
  border-radius: 5px;
  background: black;
  border: none;
  color: pink;
}

.pcg-page-wrapper .pcg-header__navigation,
.pcg-page-wrapper .pcg-header__user {
  width: initial;
}

.pcg-page-wrapper .pcg-header__navigation,
.pcg-page-wrapper .pcg-header__user {
    /* width: 50%; */
    min-height: 52px;
}

.pcg-page-wrapper a.pcg-dashboard-home,
.pcg-page-wrapper a.pcg-dashboard-home:visited {
    color: #6D6E71;
    text-decoration: none;
}

.pcg-page-wrapper .pcg-user__link--register:first-child {
  border-left: none;
}

.pcg-page-wrapper .pcg-navigation__item:not(:last-child) {
  margin-right: 0;
}

.adminBox {
    background: none;
    background-color: white;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
    border-radius: 0;
    color: #6D6E71;
    margin: 1rem 20px;
}

.adminBox h2,
.popup-container h2,
.pcgf-default .popup-container h2,
h2.default,
ul.account li.blocks h2 {
    color: #6D6E71;
    text-shadow: none;
}


ul#tree {
  /* display: flex;
  flex-wrap: wrap; */
  margin: 25px 0 0 0;
  display: flex;
  flex-wrap: wrap;
}

ul#tree > li {
  width: 33.3%;
}

ul#tree li {
  padding: 5px 10px 5px 12px;
}

ul#tree li a {
    color: #06c;
    text-decoration: underline;
    padding: 5px 11px;
    /* text-decoration: none; */
    position: relative;
    padding-left: 20px;
    display: inline-block;
}



/* ul#tree li a:before {
  position: absolute;
  left: 0;
  top: 2px;
  font-family: FontAwesome; 
  content: "\f15c";
} */

ul#tree li a.trigger:before {

  content: '\f0fe';
  font-family: FontAwesome;
  font-size: 1.1rem;
  color: #227f86;
}

ul#tree li a.trigger.active:before {
  content: '\f146';
}

ul#tree li a.trigger {

  position: absolute;
  left: 5px;
  top: 7px;
  width: 10px;
  padding: 0;
  background: none;
  
}

ul#tree > li li {
  padding: 0;
}

ul#tree li .panel {
  padding: 1rem;
  border: 2px solid rgb(227, 227, 227);
  margin-left: 0px;
}

ul#tree li .panel a {
  padding: 10px;
}

ul#tree .bold {
  color: inherit;
}


ul#tree li a.highlight {
    border-bottom: none;
    border-radius: 0;
    /* padding: 3px 12px 2px 28px */
}

ul#tree li a.highlight:before {
  top: 6px;
  left: 9px;
}



@media screen and (max-width: 767px) {
  ul#tree > li {
    width: 50%;
  }
}

@media screen and (max-width: 620px) {
  ul#tree > li {
    width: 100%;
  }
}

.pcgf-default .sticky-inner-wrapper {
  padding-top: 7rem;
}


button.form-btn {
  display: inline-block;
  font-family: inherit;
  padding: 0.475rem 2.25rem;
  margin: 0 5px;
  border-radius: 2rem;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.2;
  box-shadow: none;
  color: #ffffff !important;
  text-decoration: none;
  background: none;
  background-color: gray;
  border: 4px solid transparent;
  text-shadow: none;
  -webkit-box-shadow: 0 6px 6px rgba(0,0,0,0.1);
  box-shadow: 0 6px 6px rgba(0,0,0,0.1);
  cursor: pointer;
  -webkit-transition-property: background-color,-webkit-box-shadow;
  transition-property: background-color,-webkit-box-shadow;
  -o-transition-property: box-shadow,background-color;
  transition-property: box-shadow,background-color;
  transition-property: box-shadow,background-color,-webkit-box-shadow;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}


ul.actionButtons li.primary a,
ul.actionButtons li a,
.form-navigation-btns__container button,
.form-header__action-buttons button,
.form-btn.primary,
.form-btn.primary:hover,
button.form-btn.primary,
button.form-btn.primary:hover {
    display: inline-block;
    font-family: inherit;
    padding: 0.475rem 2.25rem;
    margin: 0 5px;
    border-radius: 2rem;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.2;
    box-shadow: none;
    color: #ffffff !important;
    text-decoration: none;
    background: none;
    background-color: #237F86;
    border: 4px solid transparent;
    text-shadow: none;
    -webkit-box-shadow: 0 6px 6px rgba(0,0,0,0.1);
    box-shadow: 0 6px 6px rgba(0,0,0,0.1);
    cursor: pointer;
    -webkit-transition-property: background-color,-webkit-box-shadow;
    transition-property: background-color,-webkit-box-shadow;
    -o-transition-property: box-shadow,background-color;
    transition-property: box-shadow,background-color;
    transition-property: box-shadow,background-color,-webkit-box-shadow;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
}

.form-header__action-buttons {
  margin: 1rem 0;
  background-color: rgba(0,0,0,0.1);
  padding: 1rem;
}


table.grid tbody td a.yellowBtn, 
table.grid tbody td a.greenBtn, 
table.grid tbody td a.redBtn,
ul.headButtons li a {
  display: inline-block;
    font-family: inherit;
    text-transform: none;
    padding: 0.275rem 1.25rem;
    margin: 0 5px;
    border-radius: 2rem;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2;
    box-shadow: none;
    color: #ffffff !important;
    text-decoration: none;
    background: none;
    background-color: #237F86;
    border: 4px solid transparent;
    text-shadow: none;
    box-shadow: 0 6px 6px rgba(0,0,0,0.1);
    cursor: pointer;
    transition-property: box-shadow,background-color;
    transition-property: box-shadow,background-color,-webkit-box-shadow;
    transition-timing-function: ease-in-out;
}

.pagination .paging-btn {
  margin-right: 0.5rem;
}

.pagination-sort .input {
  width: 22px;
  padding: 0 5px;
}

.paging__manual-page-input-wrapper .pagesBtn {
  content: 'Go';
}

.paging__manual-page-input-wrapper {
  display: inline-block;
  margin-top: 0.2rem;
}

ul.headButtons li,
ul.headButtons li a {
  height: auto;
  display: inline-block;
  margin-bottom: 0.2rem;
}



ul.actionButtons li.primary a span,
ul.actionButtons li a span,
table > colgroup,
.liquid-round .top,
.liquid-round .bottom,
#liquid-round .top,
#liquid-round .bottom,
#tabsCapend,
.topFilters,
.bottomFilters,
ul.headButtons li a span {
    display: none;
}


.center-content,
.center-content-no-top,
.center-contentFilters,
.invoice-charge-request-form form {
    background: none;
    width: 100%;

    background-color: white;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
    border-radius: 0;
    color: #6D6E71;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.liquid-round,
ul#tabs,
.pcgf-tab__list,
.pcgf-default .pcgf-tab__content,
.navigation-bar__container,
.form-main-content,
.enrollment-documents-container .enrollment-documents-container__template-wrapper .enrollment-documents-container__template,
dl.summary,
.dbCards a,
.legacy-forms .form-main {
    background: none;
    background-color: white;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
    border-radius: 0;
    color: #6D6E71;
    margin-top: 1rem !important;
}


.mainheader, 
.maincontent, 
.s4s-pathway-footer, 
.s4s-info-footer, 
#app {
  max-width: inherit;

}

.pcg-page-wrapper .pcg-main-content {
  margin-top: 0;
}
.invoice-charge-request-form form .action_bar--left {
  padding: 1rem;
}

.center-contentFilters { border-left: none;}
.center-contentFilters p {
  margin-bottom: 0;
}

.charge-request-common-info  {
  width: inherit;
}

#administrationExcerpt {
    background: none !important;
    padding: 20px 0 0 0.5rem;
}

#administrationExcerpt h1,
#HelperBox.administration h1 {
    color: #6D6E71;
    font-size: 2rem;
    font-weight: 600
}

#administrationExcerpt p {
    margin-bottom: 0;
}

.contentWrapper.adminBackground {
  background: none;
  padding-bottom: 0;
}

#HelperBox {

  box-shadow: none;
  border: none;
}

ul#grid li,
ul#grid.admin li {
  background: none;
  background-color: white;
  border: none;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
  border-radius: 0;
  color: #6D6E71;
}

ul#grid li:hover span {
  display: none !important;
}

.pcg-page-wrapper  a.pcg-red-tab-button,
.pcg-page-wrapper  button.pcg-red-tab-button {
  position: absolute;
  top: 0;
  right: 1rem;
  padding: 0.5rem 1rem;
  background-color: #A22F2F;
  border: none;
  color: white;
  transition: all 0.2s ease;
  text-decoration: none;
  border-radius: 0;
}

.pcg-page-wrapper  a.pcg-blue-tab-button,
.pcg-page-wrapper  button.pcg-blue-tab-button {
  position: absolute;
  top: 0;
  right: 1rem;
  padding: 0.5rem 1rem;
  background-color: #075b61;
  border: none;
  color: white;
  transition: all 0.2s ease;
  text-decoration: none;
  border-radius: 0;
}

.pcg-page-wrapper  a.pcg-help-tab-button,
.pcg-page-wrapper  button.pcg-help-tab-button {
  position: absolute;
  top: 0;
  right: 7.5rem;
  padding: 0.5rem 1rem;
  background-color: #237f86;
  border: none;
  color: white;
  transition: all 0.2s ease;
  text-decoration: none;
}

.pcg-page-wrapper  button.pcg-help-tab-button.js-newhire-btn {
  right: 18.4rem;
}

.pcg-banner-mild-alert {
  padding: 1rem 3rem;
  margin-bottom: 1rem;
  background-color: #fbffe7;
}

.pcg-banner-mild-alert p {
  margin-bottom: 0;
}

.pcg-help-container {
  padding-top: 1.5rem;
  position: relative;
}

.pcg-help-container-inner {
  padding: 2rem;
  background-color: #f1f1f1;
  border-radius: 20px;
}

.pcg-help-container::before {
  position: absolute;
  right: 10rem;
  top: -0.8rem;
  display: block;
  width: 20px;
  height: 20px;
  color: #f1f1f1;
  content: '\f06c';
  font-family: FontAwesome;
  font-size: 4rem;
}


.pcg-help-container h1 {
  font-size: 1.5rem;
}



/* ul#grid li.three h2 a,
ul#grid li h2 a {
  position: relative;
  top: 0;
  width: 100%;
  font-size: 1rem;
  padding-bottom: 0;
  margin-top: 1rem;
} */


/* ul#grid li span {
  position: relative;
  display: block;
} */




.gridHeader.admin th,
.gridHeader.account th,
.pcgf-default .pcgf-table th,
.js-ppl-checklists th {
    background: none;
    background-color: #237F86;
}

.pcg-page-wrapper th a,
.pcg-page-wrapper th a:visited,
.pcgf-default .pcgf-table th a,
.pcgf-default .pcgf-table th,
.js-ppl-checklists th {
    color: white;
}


/* Checklist table */
.js-ppl-checklists table {
  width: 100%;
  margin-bottom: 1rem;
}


.js-ppl-checklists th,
.js-ppl-checklists td {
  padding: 8px 15px;
  border-bottom: 1px solid #cccccc;
}

.person-container {
  position: relative;
  padding: 3rem 1rem 1rem 1rem;
  background-color: white;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
}

/* .js-return-to-list-btn {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 !important;
} */

.js-ppl-checklist-details-container > button {
  /* float: right; */
  display: inline-block;
  margin-bottom: 2rem;
}

 .form-main .js-checklist-list-paging button {
  background-color: white;
}

.js-ppl-checklist-details-container h2 {
  position: relative;
    font-size: 1rem !important;
    border-bottom: 1px solid #cccccc;
    padding: 0.5rem 3rem 0.5rem 0.5rem;
}

.js-ppl-checklist-details-container h2 b {
  position: absolute;
  right: 0.5rem;
}

.pcgf-default .js-ppl-checklist-form-container .form-navigation-btns__container {
  background-color: none !important;
}



.pagination > span {
    margin-left: 5px;
    margin-top: 1px;
    line-height: 1;
}

.bottomRibbon {
  background: none;
  background-color: #227f86;
  border-top: 4px solid #065b61;
  height: auto;
}

.bottomRibbon .blockBtns.small, 
.wizard-action-bar__container .blockBtns.small {
  border-radius: 50px;
  background: none;
  background-color: white;
  text-shadow: none;
  text-decoration: none;
  padding: 0.5rem 2rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: black;
}

.bottomRibbon .blockBtns.small img, 
.wizard-action-bar__container .blockBtns.small img {
  display: none;
}

.blockBtns,
.blockBtns.small {
  border-bottom: none;
  transition: all 0.5s ease;
}

.blockBtns:focus,
.blockBtns.small:focus {
  margin-top: 0;
  border-bottom: none;
}

.blockBtns:hover,
.blockBtns.small:hover {
  background-color: #ebebeb;
}



.bottomRibbon i {
  display: none;
}

table.grid {
  table-layout: auto;
}

table.grid td {
    word-break: break-all;
}

ul#tabs {
    padding: 0.5rem;
    margin: 0;
    background-color: #237F86;
}

ul#tabs li {
    float: none;
    display: inline-block;
    border-radius: 0;
}

ul#tabs li a {
    transition: all 0.2s ease;
    border-radius: 0;
    color: #ffffff;
}

ul#tabs li a.active {
    background: #dafbff;
    border-radius: 0;
    color: black;
}

ul#tabs li a:hover,
ul#tabs li a.active:hover {
    background: #c8f1f6;
    border-radius: 0;
}

.contentPadding .account {
    clear: both;
}

.collapsible-section__body ul {
    float: left;
}

.enrollment-documents-container .enrollment-documents-container__template-wrapper {
  margin: 0;
}

.enrollment-documents-container .enrollment-documents-container__template-wrapper .account {
  margin-bottom: 0;
}

ul.account li.blocks h2 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}


/* App forms
------------------- */
.pcgf-app {
    margin-top: 1rem;
}

.pcgf-explanation {
    margin-bottom: 0 !important;
}

.pcgf-default .pcgf-explanation__header {
    font-size: 2rem;
    color: #6D6E71;
}

.pcgf-default label.form-label {
    float: left;
}

.pcgf-default .form-control {
    height: inherit;
    border: none !important;
    padding: 0 0.5rem 1rem 0.5rem;
}

.pcgf-default .form-control textarea {
    width: 100%;
    min-width: 100% !important;
}


.pcgf-default .pcgf-tab__list {
    flex-wrap: wrap;
}

.pcgf-tab__list{
    margin: 5px 5px 0.5rem 5px !important;
    padding: 0.5rem !important;
}

.pcgf-tab__content {
    margin: 5px 5px 1rem 5px !important;
    padding: 1rem !important;
    border: none !important;
}

.pcgf-default .pcgf-tab__link {
    padding: 7px 11px 5px 11px;
    margin: 0 3px;
    border: none;
    text-decoration: underline;
    color: #06c;
    border-radius: 0;
}

.pcgf-default .pcgf-tab__link--active {
    background-color: #dafbff;
    color: black;
    text-decoration: none;
}

.pcgf-default .pcgf-tab__link:hover {
    background-color: #c8f1f6;
}


.pcgf-default .pcgf-button--primary,
.pcgf-default .pcgf-button--secondary,
.pcg-page-wrapper a.greenBtn,
.pcgf-default .form-navigation-btns__container button {
    
    font-family: inherit;
    display: inline-block;
    padding: 0.475rem 1rem;
    border-radius: 2rem;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2;
    color: #ffffff !important;
    text-decoration: none;
    background: none;
    background-color: #237F86 !important;
    border: 4px solid transparent;
    text-shadow: none !important;
    -webkit-box-shadow: 0 6px 6px rgba(0,0,0,0.1);
    box-shadow: 0 6px 6px rgba(0,0,0,0.1);
    cursor: pointer;
    -webkit-transition-property: background-color,-webkit-box-shadow;
    transition-property: background-color,-webkit-box-shadow;
    -o-transition-property: box-shadow,background-color;
    transition-property: box-shadow,background-color;
    transition-property: box-shadow,background-color,-webkit-box-shadow;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
}

.pcgf-default .pcgf-button--secondary {
    background: none;
    background-color: gray  ;
}

.pcgf-default button:hover {
    background-color: #1b5b60;
}




.form-main-content,
.legacy-forms .form-main {
    margin: 0 auto 1rem auto;
    margin-top: 0 !important;
    width: 100%;
    /* max-width: 920px; */
    position: relative;
    padding: 1.625rem 3.125rem 1.375rem 3.125rem;
}


.form-header,
.navigation-bar__container {
    margin: 50px auto 1rem auto;
    width: inherit;
    /* max-width: 920px; */
    position: relative;
    padding: 1.625rem 3.125rem 1.375rem 3.125rem;
}

.pcgf-default .navigation-bar__item_clickable .navigation-bar__item__icon-holder, 
.pcgf-default .navigation-bar__item_clickable .navigation-bar__item__label {
  text-align: left !important;
}

.pcgf-default .form-navigation-btns__container {
  background: none !important;
  background-color: #cccccc !important;
  margin-bottom: 1rem !important;
  /* clear: both; */
  /* overflow: hidden; */
  box-shadow: none !important;
}

.form-header { padding-left: 0;}

.form-navigation-btns__container {
    margin: 0 auto 1rem auto;
    /* width: 920px; */
    position: relative;
    padding: 0.625rem 3.125rem 1.375rem 0rem;
    /* padding: 0.625rem 3.125rem 1.375rem 3.125rem; */
}

.pcg-page-wrapper a.navigation-bar__item__icon-holder,
.pcg-page-wrapper a.navigation-bar__item__icon-holder:visited { 
  color: #237f86;
}
.navigation-bar__items-container {
    margin-bottom: 0 !important;
    flex-flow: column;
}


.legacy-forms .navigation-bar__container {
  padding: 0;
  background: none;
  box-shadow: none;
}

.legacy-forms .navigation-bar__items-container {
  flex-flow: wrap;
}
.legacy-forms .navigation-bar__item__label {
  padding-right: 20px;
}
.legacy-forms .navigation-bar__item__label:before {
  display: none;
}

.legacy-forms .navigation-bar__item,
.legacy-forms .navigation-bar__item label,
.legacy-forms .navigation-bar__container {
  margin-bottom: 0;
  justify-content: initial;
}

.legacy-forms .navigation-bar__container {
  margin-top: 3rem !important;
}

.navigation-bar__progress-container,
.navigation-bar__item__inner-progress,
.navigation-bar__item__inner-progress:before,
.navigation-bar__item__icon-holder > i,

.pcgf-default .navigation-bar__progress-container,
.pcgf-default .navigation-bar__item_visited .navigation-bar__item__icon-holder i,
.pcgf-default .navigation-bar__item__icon-holder i,
.pcgf-default .navigation-bar__item.navigation-bar__item_completed:before,
.navigation-bar__item.navigation-bar__item_completed:before {
    display: none !important;
}

.navigation-bar__item__label,
.pcgf-default .navigation-bar__item_clickable .navigation-bar__item__label {
  position: relative;
  padding-left: 20px;
  display: inline-block;
  text-align: left !important;
  line-height: 1.4;
}


.navigation-bar__item {
  justify-content: center;
  flex-direction: inherit;
  margin-bottom: 1rem;
}


.navigation-bar__item__label:before {
    position: absolute;
    left: 0;
    top: 0px;
    font-family: FontAwesome; 
    content: "\f304";
}

.pcg-page-wrapper .navigation-bar__item__icon-holder {
  text-decoration: none;
}

.pcg-page-wrapper .navigation-bar__item__icon-holder span:hover {
  text-decoration: underline;
}

.legacy-forms .form-app {
  padding: 2rem;
}

.pcgf-default .form-date__control {
  overflow: hidden;
}

/* Current step */
.navigation-bar__item_current .navigation-bar__item__label {
  font-weight: 700;
  text-decoration: none;
  border-bottom: 2px solid #237f86;
  padding-bottom: 0.2rem;
}


/* Completed steps */
.navigation-bar__item_completed .navigation-bar__item__label {
  font-weight: bold;
  color: #237f86;
  font-style: italic;
}
.navigation-bar__item_completed .navigation-bar__item__label:before {
  content: "\f00c";
  color: #237f86;
}

.pcgf-app label,
.pcgf-app legend,

.legacy-forms label,
.legacy-forms legend{
    font-weight: bold;
    display: block;
    background: transparent !important;
    float: none;
    color: #6D6E71;
}

.pcgf-app legend,
.legacy-forms legend { font-size: 1rem; }

.pcgf-app input[type=text], 
.pcgf-app input[type=number], 
.pcgf-app input[type=password], 
.pcgf-app input[type=email], 
.pcgf-app input[type=date], 
.pcgf-app input[type=tel], 
.pcgf-app input.react-datepicker-ignore-onclickoutside,
.pcgf-app textarea,

.legacy-forms input[type=text], 
.legacy-forms input[type=number], 
.legacy-forms input[type=password], 
.legacy-forms input[type=email], 
.legacy-forms input[type=date], 
.legacy-forms input[type=tel], 
.legacy-forms input.react-datepicker-ignore-onclickoutside,
.legacy-forms textarea {
    max-width: 400px !important;
    padding: 5px;
    display: block;
    width: 95%;
}

.pcgf-app input[type=text], 
.pcgf-app input[type=number], 
.pcgf-app input[type=password], 
.pcgf-app input[type=email], 
.pcgf-app input[type=date], 
.pcgf-app input[type=tel], 
.pcgf-app input.react-datepicker-ignore-onclickoutside,
.pcgf-app textarea,
.pcgf-app select,

.legacy-forms input[type=text], 
.legacy-forms input[type=number], 
.legacy-forms input[type=password], 
.legacy-forms input[type=email], 
.legacy-forms input[type=date], 
.legacy-forms input[type=tel], 
.legacy-forms input.react-datepicker-ignore-onclickoutside,
.legacy-forms textarea,
.legacy-forms select {
    margin: 0.5em 0 0 0;
}


.pcgf-app .form-checkbox__option label,
.pcgf-app .form-checkbox__option input,
.pcgf-app .form-radio__option label,
.pcgf-app .form-radio__option input,

.legacy-forms .form-checkbox__option label,
.legacy-forms .form-checkbox__option input,
.legacy-forms .form-radio__option label,
.legacy-forms .form-radio__option input {
  display: inline-block;
  margin-right: 0.5rem
}


.pcgf-app .form-mongo-search__results-table,
.pcgf-app .form-mongo-search__answer,

.legacy-forms .form-mongo-search__results-table,
.legacy-forms .form-mongo-search__answer {
    width: 100%;
    clear: both;
}

.characters-countdown-input_label {
    position: relative;
}

.pcgf-app .form-mongo-search__answer p,
.legacy-forms .form-mongo-search__answer p {
    display: inline;
    margin-left: 0.5rem;
}

.pcgf-app .form-mongo-search__answer,
.legacy-forms .form-mongo-search__answer {
  padding-top: 1rem;
  position: relative;
  display: block;
  clear: both;
  border-top: 7px solid white;
  background-color: #f4f4f4;
  padding: 1rem;
  border-radius: 5px;
}
.pcgf-app .form-mongo-search__answer::before,
.legacy-forms .form-mongo-search__answer::before {
  content: "Selected option:";
  display: block;
  font-weight: bold;
}

.pcgf-app .form-mongo-search__answer .form-mongo-search__answer__remove-btn,
.legacy-forms .form-mongo-search__answer .form-mongo-search__answer__remove-btn {
    background: none;
    border-radius: 50%;
    padding: 0px;
    height: 24px;
    width: 24px;
    background-color: #227f86;
    border: none;
    line-height: 0;
    color: white;
    font-weight: bold;
    position: relative;
    text-indent: -2000000px;
}

.pcgf-app .form-mongo-search__answer .form-mongo-search__answer__remove-btn::before,
.legacy-forms .form-mongo-search__answer .form-mongo-search__answer__remove-btn::before {
  position: absolute;
  top: 12px;
  left: 7px;
  text-indent: 0;
  font-family: FontAwesome;
  content: "\f00d";
}





.pcgf-app .form-address-search__answer p,
.legacy-forms .form-address-search__answer p {
  display: inline;
  margin-left: 0.5rem;
}


.pcgf-app .form-address-search__search-input input,
.legacy-forms .form-address-search__search-input input {
  display: inline;
}

.pcgf-app .form-address-search__answer,
.legacy-forms .form-address-search__answer {
padding-top: 1rem;
position: relative;
display: block;
clear: both;
border-top: 7px solid white;
background-color: #f4f4f4;
padding: 1rem;
border-radius: 5px;
}

.pcgf-app .form-address-search__answer::before,
.legacy-forms .form-address-search__answer::before {
content: "Your selected address:";
display: block;
font-weight: bold;
}

.pcgf-app .form-address-search__answer .form-address-search__answer__remove-btn,
.legacy-forms .form-address-search__answer .form-address-search__answer__remove-btn {
  background: none;
  border-radius: 50%;
  padding: 0px;
  height: 24px;
  width: 24px;
  background-color: #227f86;
  border: none;
  line-height: 0;
  color: white;
  font-weight: bold;
  position: relative;
  text-indent: -2000000px;
}

.pcgf-app .form-address-search__answer .form-address-search__answer__remove-btn::before,
.legacy-forms .form-address-search__answer .form-address-search__answer__remove-btn::before {
position: absolute;
top: 12px;
left: 7px;
text-indent: 0;
font-family: FontAwesome;
content: "\f00d";
}






.pcgf-app .question-type-text label p,
.legacy-forms .question-type-text label p  {
  float: none;
  margin-bottom: 0;
}

/* Pagination */
.pcgf-app .question-type-search button.pcgf-pagination__page-button {
  padding: 0.8rem;
  position: relative;
  background: none;
  border-radius: 5px;
  margin: 0 2px 2px 2px;
}

.pcgf-app .question-type-search button.pcgf-pagination__page-button::before {
  font-family: FontAwesome;
  position: absolute;
  top: 5px;
  left: 7px;
}

.pcgf-app .question-type-search .pcgf-pagination__container {
  line-height: 1;
  margin-top: 1rem;
}

.pcgf-app .question-type-search button.pcgf-pagination__page-button.pcgf-pagination__page-button--first-page::before {
  content: "\f100";
}
.pcgf-app .question-type-search button.pcgf-pagination__page-button.pcgf-pagination__page-button--previous-page::before {
  content: "\f104";
}
.pcgf-app .question-type-search button.pcgf-pagination__page-button.pcgf-pagination__page-button--next-page::before {
  content: "\f105";
  left: 9px;
}
.pcgf-app .question-type-search button.pcgf-pagination__page-button.pcgf-pagination__page-button--last-page::before {
  content: "\f101";
}

.pcgf-app .question-type-search .pcgf-pagination__page-number {
  display: inline-block;
  margin: 0 0.5rem;
  vertical-align: sub;
}
.pcgf-app .question-type-search .pcgf-pagination__page-number input[type=text] {
  width: 20px;
    text-align: center;
    margin: 0;
    padding: 0;
    display: inline-block;
}

.pcgf-app .question-type-search .pcgf-pagination__page-button--go {
  padding: 0 0.2rem !important;
}


.pcgf-app .form-file-upload__dropzone {
  float: none;
}
.pcgf-app .form-file-upload__dropzone button {
  margin-right: 0.5rem;
}



/* Referral forms */
.form-hideplusone .form-phone__country-code {
  display: none;
}


.pcgf-default .form-body,
.form-body {
  flex-wrap: nowrap !important;
  display: flex;
}

.pcgf-default .navigation-bar__container,
.navigation-bar__container {
  flex: 0 0 25%;
  margin-right: 1rem !important;
  width: 100% !important;
  margin-top: 0 !important;
  padding: 1.625rem 2rem 1.375rem 2rem
}

.pcgf-default .form-header,
.form-header {
  text-align: left !important;
  margin: 0 0 1rem 0 !important;
  padding: 0 !important;
  width: 100% !important;
}


.pcgf-default .form-header.form-purplebanner,
.form-header.form-purplebanner {
  background-color: #b602a0;
  margin-bottom: 1rem !important;
  padding: 1.375rem !important;
  color: white;
  font-size: 1.2rem;
  font-weight: 700;
}

.pcgf-default .form-header.form-purplebanner p,
.form-header.form-purplebanner p {
  margin-bottom: 0 !important;
  border-left: 5px white solid;
  padding-left: 1rem;
}

.pcgf-default .navigation-bar__items-container,
.navigation-bar__items-container {
  height: inherit !important;
}


tr.pcgf-table__row td {
  border-bottom: 1px solid lightgrey;
  padding-bottom: 0px;
  padding-top: 5px;
}

.form-mongo-search__display-fields {
  margin-right: 5px;
  color: inherit !important;
  background-color: #fcfcfc;
  padding: 1px 8px;
  border-radius: 5px;
  border: 1px solid #c5c5c5;
  margin-bottom: 5px;
  display: inline-block;
}


.messages-toggle {
  position: absolute;
  background-color: #387f86;
  border-bottom: 5px solid #265c60;
  top: 3.25rem;
  padding: 0.5rem 1.5rem;
  min-width: 260px;
}

.messages-toggle li {
  padding: 0.5rem 0;
  border-bottom: 1px solid #265c60;
}

.messages-toggle li:last-child {
  border: none;
}

.messages-toggle a,
.messages-toggle a:visited,
.messages-toggle a:hover {
  color: white;
  text-decoration: none;
}


@media screen and (max-width:800px) {
  .pcgf-default .form-body,
  .form-body {
    flex-wrap: wrap !important;
  }
  
  .pcgf-default .navigation-bar__container,
  .navigation-bar__container {
    flex: 100%;
    margin-right: 0rem !important;
    width: 100% !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  /* .pcgf-default .navigation-bar__item {
    margin-bottom: 0;
  } */
}

.pcgf-default .form-main__questions-container {
  background: transparent !important;
}

/* Begin: Custom control over horizontal layout of controls of forms, configurable within CMS. See ADO Task 236418 */
.form-app-custom-forms-format--flex-wrap--reduce-spacing--none .form-main__questions-container {
  display: flex;
  flex-wrap: wrap;
}

.form-app-custom-forms-format--flex-wrap--reduce-spacing--small .form-main__questions-container {
  display: flex;
  flex-wrap: wrap;
  padding-right: 10rem;
}

.form-app-custom-forms-format--flex-wrap--reduce-spacing--medium .form-main__questions-container {
  display: flex;
  flex-wrap: wrap;
  padding-right: 20rem;
}

.form-app-custom-forms-format--flex-wrap--reduce-spacing--large .form-main__questions-container {
  display: flex;
  flex-wrap: wrap;
  padding-right: 30rem;
}

[class*="form-app-custom-forms-format--flex-wrap"] .form-main__questions-container .form-main__question-format-one-per-line {
  flex-basis: 100%;
}

[class*="form-app-custom-forms-format--flex-wrap"] .form-main__questions-container .form-main__question-format-two-per-line {
  flex-basis: 50%;
  padding-right: 1.5rem;
}

[class*="form-app-custom-forms-format--flex-wrap"] .form-main__questions-container .form-main__question-format-three-per-line {
  flex-basis: 33.33%;
  padding-right: 1.5rem;
}
  
[class*=form-app-custom-forms-format--flex-wrap] .form-main__questions-container [class*=form-main__question-format] .characters-countdown-input_label {
  top: unset;
}
/* End: Custom control over horizontal layout of controls of forms, configurable within CMS. See ADO Task 236418 */

.form-control--valid {
    margin-bottom: 1rem;
    position: relative;
}



.question-type-search {
    padding: 1rem;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
}

.form-mongo-search__search-input input,
.form-mongo-search__search-input button.search-button {
    float: left;
}

.form-mongo-search__search-input button.search-button {
    margin-top: 0.5rem;
}


.social-security,
.account-number,
.routing-number {
    position: relative;
}

.social-security > label,
.account-number > label,
.routing-number > label {
    float: left;
}

.pcgf-app .account-number > label button,
.pcgf-app .routing-number > label button {
  float: right;
  margin-left: 0.5rem;
  padding: 0.2rem 0.5rem;
}

.pcgf-app .account-number > input,
.pcgf-app .routing-number > input {
  clear: both;
}

.pcgf-app .helpbox-icon {
    text-decoration: none;
}

.pcgf-app .js-whatsThisBtn {
    float: left;
    border-radius: 20px;
    font-size: 0.8rem;
}

.pcgf-app .icon-driven .js-whatsThisBtn {
  float: right;
}

.helpbox-icon {
    margin-left: 0.5rem;
}
.helpbox-icon:before {
    line-height: 0;
    font-size: 1.2rem;
}



.pcgf-default .form-navigation-btns__container {
  padding: 0;
  background: none;
  box-shadow: none;
  margin-bottom: 0;
}

.pcg-help-card {
  padding-top:4rem !important; 
}


#liquid-round.form fieldset {
  margin-top: 1rem;
}

#liquid-round.form input[type="text"],
#liquid-round.form input[type="password"],
#liquid-round.form input[type="telephone"],
#liquid-round.form select,
#liquid-round.form textarea {
  width: inherit;
}

#liquid-round.form input[type="text"],
#liquid-round.form input[type="password"],
#liquid-round.form input[type="telephone"],
#liquid-round.form textarea {
  min-width: 500px;
}


#liquid-round.form .field-validation-error {
  display: table;
}

.required-asterisk {
  color: black !important;
}

.caption-brown {
  color: black !important;
  margin-left: 0 !important;
}

/* Refferal form results */
.js-ppl-referral-registration-form-container .custom-form-answer .pcgf-btn-action,
.js-ppl-referral-registration-form-container .form-header__action-buttons {
  display: none;
}

.js-ppl-referral-registration-form-container .your-answers.border {
  border: none !important;
}

.js-ppl-referral-registration-form-container .your-answers .custom-question {
  margin-bottom: 1rem;
  padding: 0 0 1rem 0;
}

.js-ppl-referral-registration-form-container .your-answers h1.type-1 {
  padding: 20px 20px 20px 0;
}

.js-ppl-referral-registration-form-container .your-answers h2 {
  font-size: 1.1rem;
  margin-bottom: 0;
}

.js-ppl-referral-registration-form-container .your-answers .custom-question .custom-form-answer p {
  margin-bottom: 0
}

/* .js-ppl-referral-registration-form-container .pad20 {
  padding: 0;
} */

.form-main__questions-container legend ul {
  padding-left: 1rem;
  list-style: disc;
}

.active {
  background: none;
}

.pcg-page-wrapper .pcg-search-bar__submit::before {
  content: '\f002';
  font-family: FontAwesome;
}

.pcg-page-wrapper .pcg-nav-toggler__burger::before {
  content: '\f0c9';
  font-family: FontAwesome;
  font-size: 1.6rem;
}

.pcg-page-wrapper .pcg-nav-toggler.active .pcg-nav-toggler__burger::before {
  content: '\f00d';
}


.pcg-page-wrapper .icon-facebook:before, 
.pcg-page-wrapper .pcg-footer__social-link--facebook::before, 
.pcg-page-wrapper .pcg-blog-share__link--facebook::before {
  font-family: FontAwesome;
  content: '\f39e';
}

.pcg-page-wrapper .icon-twitter:before, 
.pcg-page-wrapper .pcg-footer__social-link--twitter::before, 
.pcg-page-wrapper .pcg-blog-share__link--twitter::before {
  font-family: FontAwesome;
  content: '\f099';
}

.pcg-page-wrapper .icon-instagram:before, 
.pcg-page-wrapper .pcg-footer__social-link--instagram::before, 
.pcg-page-wrapper .pcg-blog-share__link--instagram::before {
  font-family: FontAwesome;
  content: '\f16d';
}

.pcg-page-wrapper .icon-youtube:before, 
.pcg-page-wrapper .pcg-footer__social-link--youtube::before {
  font-family: FontAwesome;
  content: '\f167';
}

.pcg-page-wrapper .icon-linkedin:before, 
.pcg-page-wrapper .pcg-footer__social-link--linkedin::before, 
.pcg-page-wrapper .pcg-blog-share__link--linkedin::before {
  font-family: FontAwesome;
  content: '\f08c';
}


.reason-container textarea {
  display: block;
  width: 400px;
  height: 180px;
}

.pcg-info-container {
  padding-left: 6.125rem;
}
.pcg-info-container:before {
  font-family: FontAwesome;
  content: '\f05a';
  display: block;
  position: absolute;
  font-size: 2.5rem;
  left: 2rem;
  top: 1rem;
  color: #237f86;
}

.social-security .helpbox-content,
.account-number .helpbox-content,
.routing-number .helpbox-content
 {
  display: none;
}


.form-main h2.ppl-terms-header {
  background-color: #f2f2f2;
  color: black;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 1px solid #bababa;
  margin-bottom: 0;
}

.form-main article.ppl-terms-article {

  padding: 1rem;
  border-left: 1px solid #bababa;
  border-right: 1px solid #bababa;
  border-bottom: 1px solid #bababa;
}




/* Provider registration form
---------------------------------- */

.form-ppl-prov-reg .form-header p {
  font-size: 1.6rem;
  font-weight: bold;
}
.pcgf-default .form-ppl-prov-reg .form-main__questions-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.pcgf-default .form-ppl-prov-reg .form-main__questions-container > div {
  width: 100%;
}

.pcgf-default .form-ppl-prov-reg.form-main__questions-container>div {
  width: 100%;
}

.pcgf-default .form-ppl-prov-reg [type=radio]:checked+label:after, 
.pcgf-default .form-ppl-prov-reg [type=radio]:not(:checked)+label:after {
  width: 10px;
  height: 10px;
  top: 4px;
  left: 4px;
}

.pcgf-default .form-ppl-prov-reg [type=checkbox]:focus:checked+label:before, 
.pcgf-default .form-ppl-prov-reg [type=checkbox]:focus:not(:checked)+label:before {
  outline: none;
}

.pcgf-default .form-ppl-prov-reg [type=checkbox]:checked+label:after, 
.pcgf-default .form-ppl-prov-reg [type=checkbox]:not(:checked)+label:after {
  width: 12px;
  height: 12px;
  top: 3px;
  left: 3px;
}


.form-ppl-prov-reg .characters-countdown-input_label {
  display: none;
}


.pcgf-app .form-ppl-prov-reg input[type=text], 
.pcgf-app .form-ppl-prov-reg input[type=number], 
.pcgf-app .form-ppl-prov-reg input[type=password], 
.pcgf-app .form-ppl-prov-reg input[type=email], 
.pcgf-app .form-ppl-prov-reg input[type=date], 
.pcgf-app .form-ppl-prov-reg input[type=tel], 
.pcgf-app .form-ppl-prov-reg input.react-datepicker-ignore-onclickoutside, 
.pcgf-app .form-ppl-prov-reg textarea {
  width: 100%;
}

.form-ppl-prov-reg .form-col-2 {
  display: inline-block;
  width: 50%;
  max-width: 200px;
  padding-right: 0.5rem;
}

.form-ppl-prov-reg .form-col-2:nth-child(2n) {
  padding-right: 0;
  padding-left: 0.5rem;
}

.form-ppl-prov-reg .form-col-2 input {
  min-width: inherit;
}

.form-ppl-prov-reg .ppl-form-pass-help {
  padding: 0 0.2rem;
}

.form-ppl-prov-reg .ppl-form-pass-help p {
  margin-bottom: 0.2rem;
  margin-top: 1rem;
}

.form-ppl-prov-reg .ppl-form-pass-help ul {
  list-style: disc;
}

.form-ppl-prov-reg .ppl-form-pass-help li {
  width: 50%;
  font-size: 0.8rem;
  display: inline-block;
}

.pcgf-default .form-ppl-prov-reg .form-radio__option,
.pcgf-default .form-ppl-prov-reg .form-checkbox__option {
  margin-right: 0.5rem;
  display: inline-block;
  clear: none;
  width: inherit;
}


.pcgf-default .form-ppl-prov-reg [type=radio]:checked+label, 
.pcgf-default .form-ppl-prov-reg [type=radio]:not(:checked)+label,
.pcgf-default .form-ppl-prov-reg [type=checkbox]:checked+label, 
.pcgf-default .form-ppl-prov-reg [type=checkbox]:not(:checked)+label {
  padding-left: 25px;
  line-height: 20px;
}

.pcgf-default .form-ppl-prov-reg .form-main__questions-container>div legend {
  font-weight: bold;
  color: #6d6e71;
}


/* Address stuff */
.form-ppl-prov-reg .question-type-search {
  padding: 0;
  border: none;
  box-shadow: none;
}

.form-ppl-prov-reg .question-type-search .add-address-button {
  display: none;
}

.pcgf-default .form-ppl-prov-reg .form-main__questions-container table th {
  background: none;
  background-color: #237F86
}
.pcgf-default .form-ppl-prov-reg .form-main__questions-container table td {
  background-color: #f0f0f0;
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  vertical-align: middle;
}

.form-ppl-prov-reg .question-type-search button {
  background-color: #227f86 !important;
  color: white;
  margin-top: 0.5rem;
  margin-left: 0 !important;
  padding: 0rem 0.7rem !important;
}

.form-ppl-prov-reg .question-type-search .pcgf-table__row button {
  margin-top: 0;
}

.pcgf-app .form-ppl-prov-reg .form-address-search__answer {
  margin-top: 0;
  border-top: none;
}

.form-ppl-prov-reg .question-type-search button.form-address-search__answer__remove-btn {
  border-radius: 50% !important;
  width: 25px !important;
  height: 25px !important;
}

.form-ppl-prov-reg .border-top {
  padding-top: 2rem;
  margin-top: 3rem;
}

.form-ppl-prov-reg .no-margin-bot {
  margin-bottom: 0 !important;
}


.form-ppl-prov-reg .form-main__questions-container div.form-pop-up legend {
  font-weight: bold;
  color: #6d6e71;
}

.form-ppl-prov-reg .form-main__questions-container div.form-pop-up legend:hover {
  cursor: pointer;
}


.form-ppl-prov-reg .form-main__questions-container div.form-pop-up {
  width: 50%;
  position: relative;
}

.form-ppl-prov-reg .form-main__questions-container div.form-pop-up .form-checkbox__option__container {
  display: none;
  position: absolute;
  top: 68px;
  background: white;
  z-index: 200;
  border: 3px solid #227f86;
  min-width: 240px;
  padding: 1rem;
  box-shadow: 0 0 5px 2px rgba(0,0,0,0.2);
}

.form-ppl-prov-reg .form-main__questions-container div.form-pop-up .form-checkbox__option {
  width: 100%;
}

.form-ppl-prov-reg .form-main__questions-container div.form-pop-up .tickbox-counter {
  padding: 0.5rem;
  border: 1px solid #c3c3c3;
  position: relative;
  font-weight: normal;
  font-size: 0.8rem;
  margin-right: 1rem;
}

.form-ppl-prov-reg .form-main__questions-container div.form-pop-up .tickbox-counter:after {
  position: absolute;
  right: 7px;
  top: 6px;
  content: '\f0d7';
  font-family: FontAwesome;
  font-size: 1rem;
  color: #237F86;
}

.form-ppl-prov-reg .question-type-file {
  width: 200px;
}
.form-ppl-prov-reg .question-type-file label {
  display: none;
}
.form-ppl-prov-reg .form-file-upload__dropzone {
  border: none;
  background-image: url(/images/my-profile.png);
  background-size: auto 200px;
  background-repeat: no-repeat;
  padding-top: 200px;
}

.form-ppl-prov-reg .form-file-upload__content {
  padding: 0 !important;
}

.form-ppl-prov-reg .form-file-upload__content__browse-btn {
  color: white;
  background-color: #003694 !important;
  font-weight: bold;
  font-size: 1rem !important;
  border: none !important;
  width: 200px;
}
/* .pcgf-default .file-upload__submit-btn, 
.pcgf-default .form-address-search__custom-address-form button:not(.tox-tbtn), 
.pcgf-default .form-main__questions-container button:not(.tox-tbtn) {

} */
/* Address stuff ends */

/* Hide the 3rd bullet on Payment selection on 'VA Provider Enrollment'  */
.hide-paper-payment .form-radio__option:nth-child(4n) {
  visibility: hidden;
}

.spinner {
  width: 374px;
  background-repeat: no-repeat;
  background-position: center 40px;
  text-align: center;
  padding: 120px 80px 60px 80px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 15px 4px rgba(158, 158, 158, 0.12);
}

.spinner p {
  /* background-color: #b33a92;;
  border-radius: 20px;
  box-shadow: 0 1px 1px 1px rgb(158 158 158 / 10%); */
  color: #b33a92;
}

#ui-datepicker-div {
  z-index: 10000 !important;
}

@media handheld, screen and (max-width: 693px) and (min-width: 320px) {
  .form-ppl-prov-reg .form-col-2 {
    display: block;
    width: 100%;
  }
}


@media (max-width: 1023.98px) {
  .pcg-page-wrapper a.pcg-dashboard-home, 
  .pcg-page-wrapper a.pcg-dashboard-home:visited {
    color: white;
  }

  .pcg-header__search {
    display: none;
  }
}

@media screen and (min-width:1050px) {
    .icon-driven .form-radio__option {
      padding-right:40%;
    }
  
  
  }
  
  
  
  
  
  @media screen and (max-width:1360px) {
  
    .icon-driven .ss-panel {
      width: 300px;
    }
  
  }
  
  
  @media screen and (max-width:1250px) {
  
   
  
    .social-security-template {
      background-size: 100% 100%;
      width: 90%;
      height: 220px;
    }
  
  }
  
  @media screen and (max-width:1150px) {
    
    .icon-driven .ss-panel {
      width: 250px;
    }
  
    
  
  }
  
  @media screen and (max-width:1050px) {
  
    .ss-panel {
      float: none;
      position: relative !important;
      left: initial;
      top: initial;
      width: 100%;
      max-width: 500px;
      padding: 0;
    }
  
    .arrow-left {
      display: none;
    }
  
    .icon-driven .ss-panel {
      clear: both !important;
      width: 100%;
      max-width: 790px;
      overflow: hidden;
    }
  
    .icon-driven .ss-panel img {
      float: left;
      max-width: 200px;
    }
  
    .pad60{
      padding:20px;
    }
  
    .review-card h1:after {
      display: none;
  }
  
  
  
  }


  @media handheld, screen and (max-width: 693px) and (min-width: 320px) {

    .navigation-bar__items-container, 
    .steps-list-container {
      display: block !important;
    }

    .pcg-page-wrapper a.navigation-bar__item__icon-holder {
      margin-bottom: 0.5rem;
    }

    .navigation-bar__item {
      justify-content: left;
    }

    .dbCards,
    .signature-details__signer-type,
    .styled-signature__name,
    .styled-signature__preview-container canvas,
    .hand-written-signature__canvas {
      width:100% !important;
    }

    ul.actionButtons {
      text-align: right;
    }

    .right-columnAlt {
      width: auto;
    }

    #administrationExcerpt p {
      width: inherit;
    }

    .navigation-bar__container, 
    .form-main-content {
      padding: 1.625rem 1.625rem 1.375rem 1.625rem;
    }

    .form-header {
      padding: 1rem 2rem 0.5rem 0;
      margin-bottom: 0;
    }

    .pcg-page-wrapper .pcg-content-jumbotron {
      min-height: 160px !important;
    }

  }

  @media (max-width: 767.98px) {

    .pcg-breadcrumbs .section {
      margin-bottom: 1rem;
    }

    .span_3_of_12 {
      width: 100% !important;
    }

    .pcg-page-wrapper select {
      width:100%;
    }

    /* .pcg-page-wrapper .pcg-base-card {
        padding: 3.5rem 1rem 1rem 1rem;
    } */
  }